import "../css/Roadmap.css";
import pc_background from "../assets/pc_background.jpeg";
import tb_background from "../assets/mb_background.png";
import classnames from "classnames";
import Pins from "./pins/Pins";
import { useLayoutEffect, useState } from 'react';
import { useMediaQuery } from "react-responsive";
import { StoreCard } from './StoreCard';
import {MapData} from "../assets/variables";

const Roadmap = ({ className, onLoad, onPinClick, selectData }) => {
    const isMobile = useMediaQuery({
        query: '(min-width:815px)',
    });

    const [loading,setLoading] = useState(true);
    useLayoutEffect(() => {
        if(loading === false) {
            onLoad?.()
        }
    // eslint-disable-next-line
    },[loading])
    return (
        <>
            <div  style={{
                backgroundImage: isMobile ?  undefined : `url(${tb_background})`
            }} className={classnames("wrap", className, {})}>
                <img onLoad={()=>{
                    setLoading(false);
                }} src={isMobile ? pc_background : tb_background} alt="용두산 로드맵" className="roadmap"/>
                {loading ? null : <Pins onClick={onPinClick} selectData={selectData} onSelectPin />}
                
                <div className="storeCardWrap">
                {MapData.map(pin =>{
                    return  <StoreCard key={pin.id +"StoreCard"} name={pin.id} url={pin.link} desc={pin.text} img={pin.img}  />
                })}
                </div>
            </div>

        </>
    );
}

export default Roadmap;
