import "./css/Reset.css";
import "./css/App.css";
import Roadmap from "./components/Roadmap";
import Header from "./components/Header";
import { useState } from 'react';
import Card from "./components/Card";
import {MapData,DefaultMapData} from "./assets/variables";
import Footer from "./components/Footer";


function App() {
  const [selectedId, setSelectedId] = useState();
  const [roadMapLoaded,setRadmapLoaded] = useState(false);
  const selectMapData = MapData.find(data =>data.id === selectedId) || DefaultMapData
  const clickedFocus = () => {
    const card = document.getElementById("Card");
    card.scrollIntoView({behavior: "smooth", block: "end"});
  }
  
  const handlePinClick = (pin) =>{
    setSelectedId(selectedId === pin.id ? undefined : pin.id)
    clickedFocus();
  }

  return (
    <div  className="wraaper">
      <Header />
      <Roadmap onPinClick={handlePinClick}  selectData={selectMapData} setSelectedId={setSelectedId}  onLoad={()=>{
        setRadmapLoaded(true);
      }}  />
      {roadMapLoaded
        ? <>
            <Card id="Card" key={selectMapData.id} card={selectMapData} />
          </> : null}
        <Footer />
    </div>
  );
}

export default App;
