import "../css/App.css";
import "../css/StoreCard.css";

export const StoreCard = ({name, desc, img, url})=>{
    return <div onClick={()=>{
        window.open(url,"_self");
    }} className="storeCard">
        <img className="storeCard__img" src={img}/>
        <bold className="storeCard__name">{name}</bold>
        <p className="storeCard__desc">{desc}</p>
    </div>
}