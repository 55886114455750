import Pin from "./Pin";
import {MbPinDatas, PinDatas} from "../../assets/variables";
import { useMediaQuery } from 'react-responsive';


const Pins = ({selectData, onClick }) => {
    const isMobile = useMediaQuery({
        query: '(min-width:815px)',
    });


    const clickedPin = (pin) => {
        onClick(pin);
    }
    const targetPins = isMobile ? PinDatas : MbPinDatas;
    return (
        <>
            {targetPins.map( (pin) => (
                <Pin className={"pin" + (selectData.id === pin.id ? " active" : "")} onClick={()=>{clickedPin(pin);}} pin={pin} key={pin.id} />
            ))}
        </>
    );
}

export default Pins;
