import { useEffect} from "react";
import "../css/Card.css";
import classnames from "classnames";
import AOS from "aos";
import "aos/dist/aos.css";
import {DefaultMapData} from "../assets/variables"


function Card({ className,card, id }) {
  const isDefault = card.id === DefaultMapData.id
  useEffect(() => {
    AOS.init();
  },[card.id])

  return (
      <div id={id}  className={classnames("card", className, {})}>
        <div data-aos="fade-up" data-aos-duration="1000" className="startcard">
          <img className="startcard__img" src={card.img} alt="용두산빌리지" />
          <div className="startcard__txt">
            <h3>{card.id}</h3>
            <p>{card.text}</p>
            {!isDefault &&
            <a href={card.link}>
            <button className="card_btn">
            메뉴 둘러보기
            </button>
            </a>}
          </div>
        </div>
      </div>
  );
}

export default Card;