import "../../css/Pin.css"

function Pin({  pin, ...props }) {

    return (
            <div className="pin" style={{left: pin.left, top: pin.top}} {...props}>{pin.id}</div>
    )
}

export default Pin;
