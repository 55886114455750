import "../css/Footer.css"
import classnames from "classnames";


const Footer = ({ className }) => {
    return (
        <div className={classnames("footer", className, {})} >
            <div className="store-title">용두산빌리지 가게목록</div>
            <ul className="store-list">
                <li>
                    <a href="https://beer.m-all.in/#/">뚜용비어</a>
                </li>
                <li>
                    <a href="https://gift.m-all.in/#/">기념품샵</a>
                </li>
                <li>
                    <a href="https://snack.m-all.in/#/">용두명과</a>
                </li>
                <li>
                    <a href="https://ghost.m-all.in/">용골의전설</a>
                </li>
                <li>
                    <a href="https://haebaba.m-all.in/">한복정원</a>
                </li>
                <li>
                    <a href="https://booth.m-all.in/">체험부스</a>
                </li>
            </ul>
            <p className="store-text">SAVE MONEY, MAKE MONEY | 잔다</p>
            <i className="store-copy">Copyright ⓒ 2018-2022 JANDA All Rights Reserved</i>
        </div>
    )
}

export default Footer;