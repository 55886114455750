import "../css/Header.css";
import logo from "../assets/logo.png";
import img from "../assets/header_img.png"
import classnames from "classnames";

function Header({ className }) {
  const refreshPage = () => {
    window.location.reload()
    window.scrollTo(0, 0);
  }
  return (
    <div className={classnames("header_wrap", className, {})}>
      <div className="header">
          <div className="header__logo">
              <img src={logo} alt="용두산빌리지" onClick={refreshPage}/>
          </div>
          <div className="header__img">
              <img src={img} alt="뚜용이" />
          </div>
      </div>
    </div>
  );
}

export default Header;
