export const JG_ADDRESS = "";

export const DefaultMapData ={
        id: "용두산빌리지에 오신것을 환영합니다.",
        img: "/images/startcard.png",
        text: "원하는 가게를 선택해주세요.",
    }

export const MapData = [
    {
        id: "뚜용비어",
        img: "/images/beer.png",
        text: "뚜용이가 초대하는 맥주와 찰떡궁합 안주",
        pin:
        {
            left: '41%',
            top: '30%'
        },
        mo_pin:
        {
            left: '41%',
            top: '37%'
        },
        link: "https://beer.m-all.in/#/"
    },
    {
        id: "기념품샵",
        img: "/images/gift.png",
        text: "뚜용이 굿즈 기념품샵",
        pin:
        {
            left: '50%',
            top: '36%'
        },
        mo_pin:
        {
            left: '57%',
            top: '38%'
        },
        link: "https://gift.m-all.in/#/"
    },
    {
        id: "용두명과",
        img: "/images/snack.png",
        text: "용두산 추억의 먹거리",
        pin: 
        {
            left: '50%',
            top: '14%'
        },
        mo_pin:
        {
            left: '33%',
            top: '26%'
        },
        link: "https://snack.m-all.in/#/"
    },
    {
        id: "용골의전설",
        img: "/images/ghost.png",
        text: "용두산 귀신의 집, 마을에 남겨진 흔적을 따라 사건의 전말을 확인하라!",
        pin: 
        {
            left: '55%',
            top: '27%'
        },
        mo_pin:
        {
            left: '49%',
            top: '26%'
        },
        link: "https://ghost.m-all.in/#/"
    },
    {
        id: "한복정원",
        img: "/images/hanbok.png",
        text: "해바바의 한복체험",
        pin: 
        {
            left: '39%',
            top: '10%'
        },
        mo_pin:
        {
            left: '19%',
            top: '34%'
        },
        link: "https://haebaba.m-all.in/"
    },
    {
        id: "체험부스",
        img: "/images/booth.png",
        text: "용두산 빌리지의 특별한 체험 부스",
        pin: 
        {
            left: '10%',
            top: '36%'
        },
        mo_pin:
        {
            left: '24%',
            top: '62%'
        },
        link:"https://booth.m-all.in/"
    }
];

export const PinDatas = MapData.map(data => ({id:data.id,...data.pin}));
export const MbPinDatas = MapData.map(data => ({id:data.id,...data.mo_pin}));